import TreeViewNavigationMod from './components/TreeViewMod';
import ShowPass from './components/ShowPass';
// import AudioPlayer from './components/AudioPlayer';
import './components/lightbox';
import './components/lightboxInit';

document.addEventListener("DOMContentLoaded", () => {
  const trees = document.querySelectorAll('[role="tree"]');
  const loginForm = document.querySelector('.form-login');
  // const audioPlayers = document.querySelectorAll(".audio-block");

  if (trees) {
    trees.forEach((treeNavigation) => {
      new TreeViewNavigationMod(treeNavigation); // eslint-disable-line no-new
    });
  }

  if (document.contains(loginForm)) {
    new ShowPass(document); // eslint-disable-line no-new
  }

  // if (audioPlayers) {
  //   audioPlayers.forEach(player => new AudioPlayer(player)); // eslint-disable-line no-new
  // }
});
