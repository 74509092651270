let box = null;
let imageElements = document.querySelectorAll('[data-lightbox]');

imageElements.forEach(imageElement => {
  imageElement.onclick = (event) => {
    event.preventDefault();
    box = basicLightbox.create(`<img src='${imageElement.href}'>`);
    box.show();
  };
});

document.onkeydown = (event) => {
  if (event.key === 'Escape') {
    if (box) {
      box.close();
    }
  }
}
