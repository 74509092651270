/*
 *   Modified version treeview-navigation.js
 *
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *   https://www.w3.org/WAI/ARIA/apg/patterns/treeview/examples/treeview-navigation/#javascriptandcsssourcecode
 *   File: treeview-navigation.js
 *   Desc: Tree item object for representing the state and user interactions for a
 *       tree widget for navigational links
 */
class TreeViewNavigationMod {
  constructor(node) {
    if (typeof node !== 'object') {
      return;
    }

    document.body.addEventListener('focusin', this.onBodyFocusin.bind(this));
    document.body.addEventListener('mousedown', this.onBodyFocusin.bind(this));

    this.treeNode = node;
    this.navNode = node.parentElement;

    this.treeitems = this.treeNode.querySelectorAll('[role="treeitem"]');
    let i = 0;

    this.treeitems.forEach((treeItem) => {
      i += 1;
      const groupNode = this.getGroupNode(treeItem);

      treeItem.addEventListener('keydown', this.onKeydown.bind(this));

      if (groupNode) {
        const { id } = groupNode.dataset;
        const span = document.getElementById(`icon-${id}`);
        span.addEventListener('click', this.onIconClick.bind(this));
      }
    });
  }

  updateAriaCurrent() {
    this.treeitems.forEach((item) => {
      if (item.hasAttribute('aria-current')) {
        this.showTreeitem(item);
      } else {
        item.removeAttribute('aria-current');
      }
    });
  }

  showTreeitem(treeItem) {
    let parentNode = this.getParentTreeitem(treeItem);

    while (parentNode) {
      parentNode.setAttribute('aria-expanded', 'true');
      parentNode = this.getParentTreeitem(parentNode);
    }
  }

  getParentTreeitem(treeItem) {
    let node = treeItem.parentNode;

    if (node) {
      node = node.parentNode;

      if (node) {
        node = node.previousElementSibling;

        if (node && node.getAttribute('role') === 'treeitem') {
          return node;
        }
      }
    }

    return false;
  }

  isVisible(treeItem) {
    const flag = true;

    if (this.isInSubtree(treeItem)) {
      let mainTreeItem = treeItem;
      mainTreeItem = this.getParentTreeitem(mainTreeItem);

      if (!mainTreeItem || mainTreeItem.getAttribute('aria-expanded') === 'false') {
        return false;
      }
    }

    return flag;
  }

  isInSubtree(treeItem) {
    if (treeItem.parentNode && treeItem.parentNode.parentNode) {
      return treeItem.parentNode.parentNode.getAttribute('role') === 'group';
    }

    return false;
  }

  isExpandable(treeItem) {
    return treeItem.hasAttribute('aria-expanded');
  }

  isExpanded(treeItem) {
    return treeItem.getAttribute('aria-expanded') === 'true';
  }

  getGroupNode(treeItem) {
    let groupNode = false;
    const id = treeItem.getAttribute('aria-owns');

    if (id) {
      groupNode = document.getElementById(id);
    }

    return groupNode;
  }

  getVisibleTreeitems() {
    const items = [];

    this.treeitems.forEach((treeItemElement) => {
      if (this.isVisible(treeItemElement)) {
        items.push(treeItemElement);
      }
    });

    return items;
  }

  collapseTreeitem(treeItem) {
    if (treeItem.getAttribute('aria-owns')) {
      const groupNode = document.getElementById(
        treeItem.getAttribute('aria-owns'),
      );

      if (groupNode) {
        treeItem.setAttribute('aria-expanded', 'false');
      }
    }
  }

  expandTreeitem(treeItem) {
    if (treeItem.getAttribute('aria-owns')) {
      const groupNode = document.getElementById(
        treeItem.getAttribute('aria-owns'),
      );

      if (groupNode) {
        treeItem.setAttribute('aria-expanded', 'true');
      }
    }
  }

  expandAllSiblingTreeitems(treeItem) {
    const { parentNode } = treeItem.parentNode;

    if (parentNode) {
      const siblingTreeitemNodes = parentNode.querySelectorAll(
        ':scope > li > span > a[aria-expanded]',
      );

      siblingTreeitemNodes.forEach((siblingTreeitemNode) => {
        siblingTreeitemNode.setAttribute('aria-expanded', 'true');
      });
    }
  }

  onBodyFocusin(event) {
    const targetElement = event.target;

    if (this.treeNode.contains(targetElement)) {
      this.navNode.classList.add('focus');
    } else {
      this.navNode.classList.remove('focus');
      this.updateAriaCurrent();
    }
  }

  onIconClick(event) {
    var tgt = event.currentTarget;

    if (this.isExpanded(tgt.nextSibling.nextSibling)) {
      tgt.setAttribute('data-expanded', 'false');
      this.collapseTreeitem(tgt.nextSibling.nextSibling);
    } else {
      tgt.setAttribute('data-expanded', 'true');
      this.expandTreeitem(tgt.nextSibling.nextSibling);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  setFocusToTreeitem(treeItem) {
    treeItem.focus();
  }

  setFocusToNextTreeitem(treeItem) {
    const visibleTreeitems = this.getVisibleTreeitems();
    let nextItem = false;

    for (let i = visibleTreeitems.length - 1; i >= 0; i -= 1) {
      const treeItemElement = visibleTreeitems[i];
      if (treeItemElement === treeItem) {
        break;
      }
      nextItem = treeItemElement;
    }
    if (nextItem) {
      this.setFocusToTreeitem(nextItem);
    }
  }

  setFocusToPreviousTreeitem(treeItem) {
    const visibleTreeitems = this.getVisibleTreeitems();
    let prevItem = false;

    for (let i = 0; i < visibleTreeitems.length; i += 1) {
      const treeItemElement = visibleTreeitems[i];
      if (treeItemElement === treeItem) {
        break;
      }
      prevItem = treeItemElement;
    }

    if (prevItem) {
      this.setFocusToTreeitem(prevItem);
    }
  }

  setFocusToParentTreeitem(treeItem) {
    if (this.isInSubtree(treeItem)) {
      const treeItemElement = treeItem.parentNode.parentNode.previousElementSibling;
      this.setFocusToTreeitem(treeItemElement);
    }
  }

  setFocusByFirstCharacter(treeItem, char) {
    let start;
    let i;
    let treeItemElement;
    let index = -1;
    const visibleTreeitems = this.getVisibleTreeitems();
    const mainChar = char.toLowerCase();

    start = visibleTreeitems.indexOf(treeItem) + 1;
    if (start >= visibleTreeitems.length) {
      start = 0;
    }

    for (i = start; i < visibleTreeitems.length; i += 1) {
      treeItemElement = visibleTreeitems[i];
      if (mainChar === treeItemElement.textContent.trim()[0].toLowerCase()) {
        index = i;
        break;
      }
    }

    if (index === -1) {
      for (i = 0; i < start; i += 1) {
        treeItemElement = visibleTreeitems[i];
        if (mainChar === treeItemElement.textContent.trim()[0].toLowerCase()) {
          index = i;
          break;
        }
      }
    }

    if (index > -1) {
      this.setFocusToTreeitem(visibleTreeitems[index]);
    }
  }

  onKeydown(event) {
    const targetElement = event.currentTarget;
    let flag = false;
    const { key } = event;

    function isPrintableCharacter(str) {
      return str.length === 1 && str.match(/\S/);
    }

    if (event.altKey || event.ctrlKey || event.metaKey) {
      return;
    }

    if (event.shift) {
      if (
        event.keyCode === this.keyCode.SPACE
        || event.keyCode === this.keyCode.RETURN
      ) {
        event.stopPropagation();
      } else if (isPrintableCharacter(key)) {
        if (key === '*') {
          this.expandAllSiblingTreeitems(targetElement);
          flag = true;
        } else {
          this.setFocusByFirstCharacter(targetElement, key);
        }
      }
    } else {
      switch (key) {
        case ' ':
          flag = true;
          break;

        case 'Up':
        case 'ArrowUp':
          this.setFocusToPreviousTreeitem(targetElement);
          flag = true;
          break;

        case 'Down':
        case 'ArrowDown':
          this.setFocusToNextTreeitem(targetElement);
          flag = true;
          break;

        case 'Right':
        case 'ArrowRight':
          if (this.isExpandable(targetElement)) {
            if (this.isExpanded(targetElement)) {
              this.setFocusToNextTreeitem(targetElement);
            } else {
              this.expandTreeitem(targetElement);
            }
          }
          flag = true;
          break;

        case 'Left':
        case 'ArrowLeft':
          if (this.isExpandable(targetElement) && this.isExpanded(targetElement)) {
            this.collapseTreeitem(targetElement);
            flag = true;
          } else if (this.isInSubtree(targetElement)) {
            this.setFocusToParentTreeitem(targetElement);
            flag = true;
          }
          break;

        case 'Home':
          this.setFocusToTreeitem(this.treeitems[0]);
          flag = true;
          break;

        case 'End': {
          const visibleTreeitems = this.getVisibleTreeitems();
          this.setFocusToTreeitem(
            visibleTreeitems[visibleTreeitems.length - 1],
          );
          flag = true;
          break;
        }

        default:
          if (isPrintableCharacter(key)) {
            if (key === '*') {
              this.expandAllSiblingTreeitems(targetElement);
              flag = true;
            } else {
              this.setFocusByFirstCharacter(targetElement, key);
            }
          }
          break;
      }
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}

export default TreeViewNavigationMod;
