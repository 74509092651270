class ShowPass {
  constructor() {
    const passwordField = document.getElementById('password');
    const toggleElement = document.querySelector('.password-toggle');
    const toggleText = toggleElement.querySelector('.text-toggle');

    toggleElement.addEventListener('click', () => {
      if (passwordField.type === 'password') {
        passwordField.type = 'text';
        toggleText.textContent = 'verstecken';
      } else {
        passwordField.type = 'password';
        toggleText.textContent = 'anzeigen';
      }
    });
  }
}

export default ShowPass;
